import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Box } from '@mui/material';

import Header from './Header';
import { ConfigurationProvider } from '../providers/ConfigurationContext';
import { UserProvider } from '../providers/UserContext';
import { AuthProvider } from '../providers/AuthContext';
import { PageTitleProvider } from '../providers/PageTitleContext';
import { DocTypesProvider } from '../providers/DocTypesContext';

const Layout = () => {
  return (
    <PageTitleProvider>
      <ConfigurationProvider>
        <AuthProvider>
          <UserProvider>
            <DocTypesProvider>
              <Header />
              <Box
                component='main'
                sx={{
                  flexGrow: 1,
                  height: '100vh',
                  overflow: 'auto',
                }}
              >
                <Outlet />
                <ScrollRestoration />
              </Box>
            </DocTypesProvider>
          </UserProvider>
        </AuthProvider>
      </ConfigurationProvider>
    </PageTitleProvider>
  );
};

export default Layout;
